.Testimonials{
  display: flex;
  margin-top: 5rem;
  margin-left: 3rem;
}
.left-t{
  display: flex;
  gap:2rem;
  flex-direction: column;
} 
.left-t>span{
  text-transform: uppercase;
}
.left-t>:nth-child(1){
  font-size: 1rem;
  font-weight: bold;
  font-family:Georgia, 'Times New Roman', Times, serif;
    color: rgb(152, 181, 249);
}
.left-t>:nth-child(2)
{
   font-size: 3rem;
}
.left-t>:nth-child(3){
  color: white;
  font-size: 3rem;
  font-weight: bold;
}
.left-t>:nth-child(4){
  text-transform: none;
  text-align: justify;
  letter-spacing: 0.11rem;
  line-height: 2rem;
  max-width: 50rem;
}

.right-t{
  flex: 1;
  position: relative;
}
.right-t>img{
  padding-left: 15rem;
  height: 15rem;
  width: 22rem;
}
.right-t>:nth-child(1)
{
  position: absolute;
  height: 15rem;
  width: 22rem;
  border: 2px solid rgb(152,181,249);
  background-color: transparent;
  z-index: -1;
  right: 3rem;
  top: -2rem;

}
.right-t>:nth-child(2)
{
  position: absolute;
  height: 15rem;
  width: 22rem;
  background-color:   rgb(152,181,249);
  z-index: -1;
  left: 14rem;
  top: -1rem;

}

.t-arrows{
  display: flex;
  gap:1rem;
  margin-top: 3rem;
  margin-left: 3rem;
}
.t-arrows>img{
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  transition: all 0.5;
}
.t-arrows>img:hover{
  transform: scale(1.1);
}