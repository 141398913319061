.plans-container{
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plans{
  /* margin-top: 40px; */
  align-items: center;
  justify-content: center;
  display: flex;
  gap:3rem;  
}

.plan{
  display: flex;
  flex-direction: column;
  background-color: rgb(104, 104, 104);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
  transition: all 1.5s;
}

.plan>:nth-child(1)
{
  fill:rgb(124, 155, 228);
  height: 2rem;
  width: 2rem;
}
.plan>:nth-child(2)
{
  font-size: 1.5rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.plan>:nth-child(3)
{
  font-size: 2.5rem;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif ;
}
.plan>:nth-child(5)
{
  font-size: 0.8rem;

}

.features{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature{
  text-align: left;
  align-items: center;
  gap: 1rem;
}


.feature>img{
  width: 1rem;
  margin-right: 0.5rem;
  }

.plan:hover{
  background-color: rgb(124, 155, 228) ;
  transform: scale(1.1);
  
}  

.plan:hover>:nth-child(1)
{
  fill: white;
}

.plan:hover>.btn
{
  background-color: whitesmoke;
}