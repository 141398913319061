.Reasons{
  margin-top: 100px;
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}
.left-r{
  flex:1 1;
  display: grid;
  grid-template-columns: repeat(3 ,1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}
.left-r>img{
  object-fit: cover;
}
.left-r>:nth-child(1)
  {
    width: 14rem;
    grid-row: 1/3;
    height: 30.5rem;
  }
  .left-r>:nth-child(2)
  {
    width: auto;
    height: 21.5rem;
    grid-column: 2/4;
  }
  .left-r>:nth-child(3)
  {
    width: 12rem;
    grid-column: 2/3;
    grid-row: 2;
  }
  .left-r>:nth-child(4)
  {
    width:12rem;
    grid-row: 2;
    grid-column: 3/4;
  }
.right-r{
  flex:1 1;
  text-transform: uppercase;
  gap:1rem;
  display: flex;
  flex-direction: column;
}
.right-r>span{
  font-weight: bold;
  font-size: 1.5rem;
  color: rgb(124, 155, 228);
}
.right-r>div{
  font-size: 4rem;
  color: white;
  font-weight: bold;
}
.details-r>div{
  display: flex;
  gap:1rem;
  padding: 1rem;
}
.details-r>div>img{
  height: 2rem;
  width: 2rem;
}
.details-r>div>span{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1rem;
  color: whitesmoke;
  
}
.partner-main{
  display: flex;
  gap: 2rem;
}

.partners{
  display: flex;
  gap:1rem;
}

.partners>img{
  height: 2rem;
  width: 2rem;
}