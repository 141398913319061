.Join{
  margin-top: 5rem;
  display: flex;
  gap: 20rem;
}
.left-j{
  margin-left: 3rem;
   display: flex;
   flex-direction: column;
  }
.left-j>span{
  color: white;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
}  
.rigth-j{
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.email-container{
  display: flex;
  gap: 2rem;
  background-color: gray;
  padding: 1rem 2rem;
}

.email-container>input{
  background-color: transparent;
  border:none;
  outline:none;
  color: rgb(215, 215, 215);
}

.email-container>input::placeholder{
  color: white;
}

.btn-j:hover{
    background-color: rgb(103, 103, 161);
}