.Footer-container{
  position: relative;
}
.Footer-container>hr{
  border: 1px solid lightgray;
}
.footer{
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  /* height: 4rem; */
}
.social{
  display: flex;
  gap: 2rem;
}
.footer>.social>img{
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}
.Logo-f{
  display: flex;
  gap: 1rem;
  margin-left:10rem ;
}
.Logo-f>img{
  margin-top: -5rem;
  /* padding-left:5rem ; */
  width: 15rem;
  height: 15rem;
}
.Logo-f>:nth-child(1){
  font-size: 3rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.Logo-f>.Address{
  display: flex;
  flex-direction: column; 
  font-size: 1rem;
   font-family: Verdana, Geneva, Tahoma, sans-serif;

}
.Logo-f>.Address>:nth-child(1){
  font-weight: bold;
}
.Logo-f>.Address>:nth-child(2){
   color: rgb(204, 201, 201);
}