.header{
  display:flex;
  justify-content:space-between;
  padding-right: 100px;
}
.menu{
  display: flex;
  flex-direction: row;
  gap: 10rem;
}
.menubar{
  display: none;
}

.logo{
  margin: -5rem;
  padding-left:8rem ;
  width: 15rem;
  height: 15rem;
}
.header-menu{
  list-style: none;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  text-align: center;
}
.header-menu>li:hover{
  cursor: pointer;
  color: rgb(124, 155, 228);
}

@media screen and (max-width:768px){
  .menubar{
    display: unset;
  }
 
  .menubar>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
  }
  .menubar>img:hover{
    transform: scale(1.1);
    
  }
}