.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;  
}

.btn{
  background-color: rgb(124, 155, 228) ;
  display: flex;
  text-align: center;
  justify-content: center;
  color: black;
  padding:0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
}

.btn:hover{
  cursor: pointer; 
}