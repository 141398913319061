.hero{
  display: flex;
  justify-content: space-between;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: whitesmoke
}
.left-h{
  padding: 10px;
  flex:3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.right-h{
  flex:1;
  position: relative;
  background-color: rgb(124, 155, 228);
  display: flex;
  flex-direction: column;
}
.right-h>ul{
  list-style: none;
  display: flex;
  /* gap:4rem; */
}

.thebestad{
  margin-top: 4rem;
  background-color: rgb(32, 32, 32);
  border-radius: 4cm;
  width:fit-content;
  padding:20px 13px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.thebestad>span{
  z-index: 2;
}
.thebestad>div{
  position: absolute;
  background-color:rgb(124, 155, 228) ;
  width: 5.4em;
  height: 80%;
  left: 8px;
  border-radius:3rem ;
  z-index: 1;
}
.hero-text{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  text-overflow: inherit;
}

.figures{
  display:flex;
  gap:2rem;
}

.figures>div{
  display: flex;
  flex-direction: column;
}
.figures>div>span:nth-of-type(1){
  color: white;
  font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
  color: rgb(178, 178, 178);
  text-transform: uppercase;
}
.figures>.calories
{
  display: flex;
  gap:2rem;
  background-color: rgb(214, 213, 213);
  color:black;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width:max-content;
  margin-left: 5rem ;
}

.hero-buttons{
   display: flex;
   gap:1rem;
   font-weight: normal;
}
.hero-buttons>:nth-of-type(1){
  color: white;
  background-color: rgb(124, 155, 228) ;
  width: 8rem;
}
.hero-buttons>:nth-of-type(1):hover{
  background-color: rgb(81, 102, 151);
}
.hero-buttons>:nth-of-type(2){
  color: white;
  background-color: transparent ;
  width: 8rem;
  border:2px solid rgb(124, 155, 228);
}
.hero-buttons>:nth-of-type(2):hover{
  background-color: rgb(57, 54, 54);
}
.right-h>ul>div
  {
    color: white;
    /* margin-top: 4rem; */
    background-color: rgb(32, 32, 32);
    border-radius: 4cm;
    width:fit-content;
    padding:10px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
.right-h>ul>li>.btn{
  color:black; 
  background-color: white;
  margin-top: 20px;
  margin-left: 100px;
  width: max-content;

  border-image: repeating-linear-gradient(135deg,#1f1e1d 0 5px,#282726 0 5px,#000000 0 5px) 8;
  -webkit-mask: conic-gradient(from 180deg at top 5px right 5px, #0000 90deg,#000 0)
     var(--_i,200%) 0  /200% var(--_i,8px) border-box no-repeat,
    conic-gradient(at bottom 8px left  8px,  #0000 90deg,#000 0)
     0   var(--_i,200%)/var(--_i,8px) 200% border-box no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: .3s, -webkit-mask-position .3s .3s;

}
.right-h>ul>li>.btn:hover{
  background-color: rgb(212, 212, 212);
  --_i: 100%;
  transition: .3s, -webkit-mask-size .3s .3s;
  
}
.heart-rate>img{
  width: 12rem;
  height: 12rem;
  padding-left: 90px;
  padding-top: 50px;  
}
.heroimage{
  position: relative;
  top:0.1rem;
  right:14rem;
  width: 30rem;
  z-index: 3;
}
.heroimagebg{
  position: absolute;
  height: 20rem;
  width:20rem; 
  top:350px;
  z-index:1;
}

.calories-pic{
  height: 7rem;
  width: 7rem;
}
.calories>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.calories>div>:nth-child(1){
  color: grey;
}
.calories>div>:nth-child(2){
  color: black;
  font-size: 1.5rem;
}


@media screen and (max-width:768px) {
  .hero{
    flex-direction: column;
  }
  .thebestad{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text>div:nth-of-type(3)
  {
     font-size: small;
     font-weight: 200;
     letter-spacing: 1px;
     text-align: center;
  }
  .hero-buttons{
    justify-content: center;
  }
  .figures>div>span:nth-of-type(1){
    font-size: large;
  }
  .figures>div>span:nth-of-type(2){
    font-size: small;
  }
  .right-h{
    position: relative;
    background:none;
  }
  .right-h>ul>li>.btn{
    margin:0;
    margin-left: 2rem;
    justify-content: center;
    align-items: center;
    width: 10rem;
    border-radius: 0.5rem;
   
  }
  .calories{
    position: absolute;
    transform: scale(0.8);
    top: 42.5rem;
    right: 11rem;

  }
  .heart-rate>img{
     transform: scale(0.7);
     margin-left: 2.5rem;
  }
  .heroimage{
    transform: scale(0.7);
    margin-left: 6.5rem;
  }
}