.Programs{
  display: flex;
  flex-direction: column;
  gap:2rem;
  padding: 0 2rem;
  margin-top: 100px;
  margin-bottom: 50px;
}
.programs-header{
  display: flex;
  gap:5rem; 
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

.right-arrow{
  height: 1rem;
  width: 1rem;
}
.program-categories{
  display:flex;
  gap:1rem; 
}
.category{
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: grey;
  padding: 2rem;
  gap:1rem;
  color: white;
  justify-content: space-between;
  transition: all 0.7s;
}
.category>:nth-child(1){
  fill: white;
  width: 2rem;
  height: 2rem;
  animation: flip_left 8s forwards;

}
.category>:nth-child(2){
  font-size: 1.5rem;
  font-weight: bold;
}
.category>:nth-child(3){
   font-size: 0.9rem;
   line-height: 30px;
}
.join-now{
  display: flex;
  align-items: center;
  gap: 2rem;
}
.category:hover{
  background-color: rgb(124, 155, 228);
  cursor: pointer;
  transform: scale(1.2);
  z-index: 4;
}

.category:hover .right-arrow{
  
  transform: scale(1.2);
}
.category:hover .join{
  text-decoration: underline;
}

@media screen and (max-width:768px) {
    .Programs{
      flex-direction: column;
    }
    .programs-header{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: 0;
      padding: 0;
      font-size: xx-large;
     margin-bottom: 2rem;
    }
    .program-categories{
      display: flex;
      flex-direction: column;
    }
}